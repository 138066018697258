import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  display: flex;
  gap: 20px;

  .pixi-trialist-empty-screen-card {
    position: relative;
    overflow: hidden;
    height: 340px;
    padding: 20px;
    border-radius: 28px;
    text-align: left;
    z-index: 1;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;
    transition: transform ease-in 0.1s;

    * {
      margin: 0;
    }

    .card-heading {
      display: flex;
      flex-direction: column;

      h4 {
        font-size: 16px;
        line-height: 1.5;
        font-weight: bold;
      }

      p {
        font-size: 12px;
        line-height: 1.333;
        color: var(--color-grey70);
      }
    }

    .card-link {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.333;
      display: flex;
      align-items: center;
      gap: 5px;

      i[name="arrow-right-circle"] {
        background-color: var(--color-grey100);
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      border-color: var(--color-yellow-500);
      transform: scale(1.02);

      :before {
        width: 720px;
        height: 720px;
        background: var(--color-yellow-500);
        right: -355px;
        top: -256px;
      }

      .card-link {
        text-decoration: underline;
      }
    }

    &.pixi-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      width: 367px;
      height: 336px;
      cursor: pointer;

      background: var(--color-black-100);

      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      border-radius: 16px;

      .pixi-icon {
        display: inline-block;
        vertical-align: middle;
        width: 57.28px;
        height: 22.26px;
        min-height: 22.26px;
        left: 95.04px;
        top: 5.87px;
        margin: 0 4px;
        transform: translateY(-5px);
      }

      .card-image-container {
        height: 210px;
        min-height: 210px;
        display: flex;
        align-items: center;
        justify-content: center;

        flex-direction: column;
        width: 100%;
        z-index: -9;
        align-items: flex-start;
        background: linear-gradient(
          to bottom,
          rgb(123, 97, 255) 10%,
          rgba(123, 97, 255, 0.3) 80%,
          rgba(123, 97, 255, 0.1) 95%,
          #000000 100%
        );

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        img {
          max-height: 100%;
          width: auto;
          object-fit: contain;
        }
      }

      .card-heading {
        justify-content: center;
        padding: 0px 20px 30px;
        gap: 10px;
        isolation: isolate;

        width: 100%;
        height: 126px;
        background: var(--color-black-100);

        h4 {
          font-size: 20px;
          font-weight: 900;
          line-height: 28px;
          color: var(--color-white-100);
        }

        p {
          font-size: 12px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--color-white-100);
        }
      }

      .pixi-device-img {
        transition: all 0.2s ease-out;
        position: absolute;
        right: 57px;
        z-index: -1;
        height: 145px;
      }
    }

    &.watch-video-tutorial-card {
      max-width: 460px;
      padding: 0px;
      width: 367px;
      height: 336px;
      background-color: var(--color-white-200);
      border: 2px solid var(--color-white-500);
      justify-content: flex-start;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      border-radius: 16px;

      .wistia_embed {
        display: inline-block;
        position: relative;
        width: 320px;
        height: 180px;
        ${Theme.default.borderRadius};
        overflow: hidden;
        box-shadow: 0px 30px 20px -20px rgba(152, 124, 0, 0.4);

        div > div > div:first-child {
          ${Theme.default.borderRadius};
        }
      }

      .card-image-container {
        height: 210px;
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        flex-direction: column;
        width: 100%;
      }

      .play-icon {
        display: inline-block;
        width: 15.13px;
        height: 18px;
        min-height: 5px;
        left: 5.88px;
        top: 3px;
        margin-right: 10px;
      }

      .card-heading {
        justify-content: center;
        padding: 0px 20px 30px;
        gap: 10px;
        isolation: isolate;

        width: 100%;
        height: auto;
        pointer-events: none;

        h4 {
          font-size: 20px;
          font-weight: 900;
          line-height: 28px;
          color: var(--color-black-100);
        }

        p {
          font-size: 12px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--color-black-100);
        }
      }

      :hover {
        :before {
          width: 1000px;
          height: 1000px;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    width: 100%;
    flex-direction: column;
    align-items: center;

    .pixi-trialist-empty-screen-card {
      width: 100%;
      max-width: 318px;
      border-radius: 16px;
      height: auto;
      overflow: hidden;
      cursor: pointer;

      &.pixi-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        width: auto;
        height: 283px;
        cursor: pointer;

        background: var(--color-black-100);

        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        border-radius: 16px;

        .card-image-container {
          height: 157px;
          min-height: 157px;
          width: 100%;
          background: linear-gradient(
            to bottom,
            rgb(123, 97, 255) 10%,
            rgba(123, 97, 255, 0.2) 90%,
            #000000 100%
          );

          img {
            max-height: 100%;
            height: 120.59px;
            width: 250.45px;
            top: 13.83px;
            left: 13.07px;
            object-fit: contain;
          }
        }

        .card-heading {
          justify-content: center;
          padding: 0px 20px 20px;
          gap: 10px;
          isolation: isolate;

          width: 100%;
          height: 126px;
          background: var(--color-black-100);

          h4 {
            font-size: 20px;
            font-weight: 900;
            line-height: 28px;
            color: var(--color-white-100);
          }

          p {
            font-size: 12px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--color-white-100);
          }
        }

        .pixi-device-img {
          transition: all 0.2s ease-out;
          position: absolute;
          right: 57px;
          z-index: -1;
          height: 120.59px;
          top: 0px;
          left: 13.07px;
          gap: 0px;
          opacity: 0px;
        }
      }

      &.watch-video-tutorial-card {
        background: transparent;
        padding: 0;
        border: none;
        max-width: 318px;
        height: auto;
        cursor: pointer;

        .card-image-container {
          display: flex;
          align-items: center;
          justify-content: center;

          flex-direction: column;
          width: 100%;
          display: none;
        }
        .card-heading {
          padding: 20px 20px 20px;
          pointer-events: auto;
        }
      }
    }
  }
`;
