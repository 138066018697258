import { LoaderBar } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Locale, TitlePageSufix } from "../../constants/constants";
import ErrorBoundary from "../ErrorBoundary";

type ComponentChunkLoader = () => any; // todo: define proper type for dynamic `import()` return value

interface Props {
  contextName: Context;
  componentName: string;
  props?: any;
  title?: string;
}

interface State {
  Component:
    | React.ComponentClass<OptionalProps>
    | React.StatelessComponent<OptionalProps>
    | null;
  loadedContexts: ContextsCollection;
  componentName: string;
  contextName: Context;
}

interface ContextsCollection {
  [name: string]: any; // todo: define proper type
}

interface OptionalProps {
  onLanguageChange?: (locale: Locale) => void;
}

export enum Context {
  Screens = "Screens",
  OnboardingPage = "OnboardingPage",
  Medias = "Medias",
  Links = "Links",
  Sites = "Sites",
  Trashes = "Trashes",
  Playlists = "Playlists",
  Channels = "Channels",
  Signup = "Signup",
  Signin = "Signin",
  VerifyEmail = "VerifyEmail",
  Account = "Account",
  Organization = "Organization",
  Billing = "Billing",
  BillingLatest = "BillingLatest",
  BillingMaintenance = "BillingMaintenance",
  Users = "Users",
  Spaces = "Spaces",
  Developer = "Developer",
  Organizations = "Organisations",
  OrganizationSSO = "OrganizationSSO",
  OrganizationSSOLogs = "OrganizationSSOLogs",
  Apps = "Apps",
  Metrics = "Metrics",
  Canvas = "Canvas",
  Themes = "Themes",
  Font = "Font",
  GenericNotFound = "GenericNotFound",
  System = "System",
  People = "People",
  PeopleOwners = "PeopleOwners",
  PeopleGroups = "Groups",
  PeopleGroupsRoles = "PeopleGroupsRoles",
  PeopleSSO = "PeopleSSO",
  Orgs = "Orgs",
  PlaybackLogs = "PlaybackLogs",
  ProofOfPlay = "ProofOfPlay",
  ProofOfPlayContentDetail = "ProofOfPlayContentDetail",
  Logs = "Logs",
  Credentials = "Credentials",
  CustomerMigration = "CustomerMigration",
  CustomerMigrationBilling = "CustomerMigrationBilling",
  FinishMigration = "FinishMigration",
  FinishProcess = "FinishProcess",
  PaymentUpdate = "PaymentUpdate",
  Logout = "Logout",
  UnsubscribeNotification = "UnsubscribeNotification",
  Welcome = "Welcome",
  DesignYourContent = "DesignYourContent",
}

const CONTEXT_LOADERS: { [name: string]: ComponentChunkLoader } = {
  [Context.Trashes]: () => import("../../routerContexts/trash"),
  [Context.Screens]: () => import("../../routerContexts/screens"),
  [Context.OnboardingPage]: () => import("../../routerContexts/onboarding"),
  [Context.Playlists]: () => import("../../routerContexts/playlists"),
  [Context.Channels]: () => import("../../routerContexts/channels"),
  [Context.Medias]: () => import("../../routerContexts/media"),
  [Context.Links]: () => import("../../routerContexts/links"),
  [Context.Sites]: () => import("../../routerContexts/sites"),
  [Context.VerifyEmail]: () => import("../../routerContexts/verify-email"),
  [Context.Account]: () => import("../../routerContexts/account"),
  [Context.Organization]: () => import("../../routerContexts/organization"),
  [Context.OrganizationSSO]: () => import("../../routerContexts/organization"),
  [Context.OrganizationSSOLogs]: () =>
    import("../../routerContexts/organization"),
  [Context.Billing]: () => import("../../routerContexts/billing"),
  [Context.BillingLatest]: () => import("../../routerContexts/billinglatest"),
  [Context.BillingMaintenance]: () =>
    import("../../routerContexts/billingMaintenance"),
  [Context.Spaces]: () => import("../../routerContexts/spaces"),
  [Context.Developer]: () => import("../../routerContexts/developer"),
  [Context.Canvas]: () => import("../../routerContexts/canvas"),
  [Context.Metrics]: () => import("../../routerContexts/metrics"),
  [Context.Apps]: () => import("../../routerContexts/apps"),
  [Context.Themes]: () => import("../../routerContexts/themes"),
  [Context.Font]: () => import("../../routerContexts/themes"),
  [Context.GenericNotFound]: () =>
    import("../../routerContexts/genericnotfound"),
  [Context.System]: () => import("../../routerContexts/system"),
  [Context.People]: () => import("../../routerContexts/people"),
  [Context.PeopleOwners]: () => import("../../routerContexts/people"),
  [Context.PeopleGroups]: () => import("../../routerContexts/groups"),
  [Context.PeopleGroupsRoles]: () => import("../../routerContexts/groups"),
  [Context.PeopleSSO]: () => import("../../routerContexts/people"),
  [Context.Logs]: () => import("../../routerContexts/logs"),
  [Context.PlaybackLogs]: () => import("../../routerContexts/playback-logs"),
  [Context.ProofOfPlay]: () => import("../../routerContexts/proof-of-play"),
  [Context.ProofOfPlayContentDetail]: () =>
    import("../../routerContexts/proof-of-play"),
  [Context.Credentials]: () => import("../../routerContexts/credentials"),
  [Context.CustomerMigration]: () =>
    import("../../routerContexts/customerMigration"),
  [Context.FinishMigration]: () =>
    import("../../routerContexts/finish-migration"),
  [Context.FinishProcess]: () => import("../../routerContexts/finish-process"),
  [Context.PaymentUpdate]: () => import("../../routerContexts/payment-update"),
  [Context.Logout]: () => import("../../routerContexts/logout"),
  [Context.UnsubscribeNotification]: () =>
    import("../../routerContexts/unsubscribeNotification"),
  [Context.Welcome]: () => import("../../routerContexts/welcome"),
};

const loadedContexts: ContextsCollection = {};

class ComponentLoader extends React.Component<
  Props & RouteComponentProps<any> & OptionalProps,
  State
> {
  public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (
      nextProps.componentName !== prevState.componentName ||
      nextProps.contextName !== prevState.contextName
    ) {
      return {
        componentName: nextProps.componentName,
        contextName: nextProps.contextName,
      } as State;
    }

    return null;
  }
  public state: State = {
    Component: null,
    componentName: this.props.componentName,
    contextName: this.props.contextName,
    loadedContexts: {},
  };

  public async componentDidMount() {
    this._updateComponent(this.props);
    document.title = this.props.title || TitlePageSufix;
  }

  public async componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.componentName !== prevProps.componentName ||
      this.props.contextName !== prevProps.contextName
    ) {
      this._updateComponent(this.props);
    }
    document.title = this.props.title || TitlePageSufix;
  }

  public _updateComponent = async (props: Props) => {
    const module = loadedContexts[props.contextName]
      ? loadedContexts[props.contextName]
      : await CONTEXT_LOADERS[props.contextName]();
    if (!loadedContexts[props.contextName]) {
      loadedContexts[props.contextName] = module;
    }

    const componentClass = module[props.componentName];

    if (!componentClass) {
      throw new Error(
        `'${props.componentName}' is not exported in context ${props.contextName}`,
      );
    }

    this.setState({
      Component: componentClass,
    });
  };

  public render() {
    const { props } = this.props;
    const Component = this.state.Component && (
      <this.state.Component {...props} />
    );
    return (
      <ErrorBoundary
        options={{ showReportDialog: true }}
        value={{
          componentName: this.state.componentName,
          contextName: this.state.contextName,
        }}
      >
        {this.state.Component === null ? <LoaderBar /> : Component}
      </ErrorBoundary>
    );
  }
}

/* I used withRouter here to force ComponentLoader to rerender when some data in react context updates (like translations
 * or apollo cache). Still not sure about the exact roots of the problem. */
export default withRouter(ComponentLoader) as React.ComponentType<Props>;
