import { AppContextType } from "src/AppContextProvider/type";
import { SUBSCRIPTION_STATUS } from "src/constants/constants";
import { FEATURE_FLAGS_ENUM } from "src/constants/featureFlag";
import { isUnderDevelopment } from "src/utils/featureFlag";

export const isPixiTrialist = (context: AppContextType) => {
  const isTrialist =
    context.subscription?.status === SUBSCRIPTION_STATUS.IN_TRIAL;
  const isPixiEnabled = context.shouldShowFeature(
    FEATURE_FLAGS_ENUM.PIXI_TRIALIST,
  );
  return isUnderDevelopment(context) && isTrialist && isPixiEnabled;
};
