import * as React from "react";
import classNames from "clsx";
import { Styled } from "./styles";

import arrowImage from "./img/arrow.svg";
import emptyChannel from "./img/emptystate-channel.svg";
import emptyPlaylist from "./img/emptystate-playlist.svg";
import emptyMedia from "./img/emptystate-media.svg";
import emptyLink from "./img/emptystate-link.svg";
import emptyApp from "./img/emptystate-apps.svg";
import emptyScreenNoPermission from "./img/emptystate-screen-no-permission.svg";
import emptyThemesImage from "./img/emptystate-theme.svg";
import emptyTwoRowImage from "./img/2-row-empty.svg";
import emptyThreeRowImage from "./img/3-row-empty.svg";
import emptyStateOwner from "./img/empty-owner.svg";
import emptySearchScreens from "./img/emptystate-search-screen.svg";
import emptySearchChannels from "./img/emptystate-search-channel.svg";
import emptySearchPlaylists from "./img/emptystate-search-playlist.svg";
import emptySearchMedia from "./img/emptystate-search-media.svg";
import emptySearchLinks from "./img/emptystate-search-link.svg";
import emptySearchLogs from "./img/empty-search-logs.svg";
import emptySearchApps from "./img/emptystate-search-apps.svg";
import emptySearchCanvas from "./img/emptystate-search-canvas.svg";
import emptySearchPeople from "./img/empty-search-people.svg";
import emptySearchGroups from "./img/empty-search-groups.svg";
import emptySearchSpaces from "./img/empty-search-spaces.svg";
import emptyCanvasInstances from "./img/emptystate-canvas.svg";
import emptyOperatingHours from "./img/operating-hours.svg";
import emptyFonts from "./img/emptystate-font.svg";
import emptyProofOfPlayAds from "./img/empty-proof_of_play-ads.svg";
import emptyGallery1 from "./img/empty-gallery-1.png";
import emptyGallery2 from "./img/empty-gallery-2.png";
import emptyGallery3 from "./img/empty-gallery-3.png";
import emptyTemplateGallery from "./img/emptystate-gallery-template.svg";
import { useAppContext } from "../../hooks/useAppContext";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import EmptyScreenSection from "./EmptyScreenSection";
import { EmptyDashboardSection } from "./EmptyDashboardSection";
import { isPixiTrialist } from "../../pages/Welcome/utils";

export interface EmptyStateProps {
  className?: string;
  children?: React.ReactNode;
  cover?: boolean;
  section: string;
  onAddNewItem?: () => void;
}

const getImage = (section: string): string | string[] => {
  let emptyImage;
  switch (section) {
    case "apps":
      emptyImage = emptyApp;
      break;
    case "media":
      emptyImage = emptyMedia;
      break;
    case "channel":
      emptyImage = emptyChannel;
      break;
    case "channel-detail":
      emptyImage = emptyLink;
      break;
    case "link":
      emptyImage = emptyLink;
      break;
    case "playlist":
      emptyImage = emptyPlaylist;
      break;
    case "playlist-detail":
      emptyImage = emptyPlaylist;
      break;
    case "themes":
      emptyImage = emptyThemesImage;
      break;
    case "screen":
    case "screen-no-permission":
    case "screen-new":
      emptyImage = emptyScreenNoPermission;
      break;
    case "new-group-member":
      emptyImage = emptyTwoRowImage;
      break;
    case "user-group":
    case "space-detail":
      emptyImage = emptyTwoRowImage;
      break;
    case "invite-people":
    case "search":
    case "group-list":
      emptyImage = emptyThreeRowImage;
      break;
    case "owner":
      emptyImage = emptyStateOwner;
      break;
    case "search-screens":
      emptyImage = emptySearchScreens;
      break;
    case "search-channels":
      emptyImage = emptySearchChannels;
      break;
    case "search-playlists":
      emptyImage = emptySearchPlaylists;
      break;
    case "search-media":
      emptyImage = emptySearchMedia;
      break;
    case "search-links":
      emptyImage = emptySearchLinks;
      break;
    case "search-apps":
      emptyImage = emptySearchApps;
      break;
    case "search-canvas":
      emptyImage = emptySearchCanvas;
      break;
    case "search-people":
      emptyImage = emptySearchPeople;
      break;
    case "search-groups":
      emptyImage = emptySearchGroups;
      break;
    case "search-spaces":
      emptyImage = emptySearchSpaces;
      break;
    case "search-logs":
      emptyImage = emptySearchLogs;
      break;
    case "playback-logs":
      emptyImage = emptySearchLogs;
      break;
    case "proof-of-play-ads":
      emptyImage = emptyProofOfPlayAds;
      break;
    case "canvas-instances":
      emptyImage = emptyCanvasInstances;
      break;
    case "canvas-instances-with-published-template":
    case "canvas-templates-with-published-template":
      emptyImage = [emptyGallery1, emptyGallery2, emptyGallery3];
      break;
    case "operating-hours":
      emptyImage = emptyOperatingHours;
      break;
    case "canvas-templates":
    case "template-gallery":
      emptyImage = emptyTemplateGallery;
      break;
    case "fonts":
      emptyImage = emptyFonts;
      break;
    default:
      emptyImage = "";
      break;
  }
  return emptyImage;
};

const EmptyState = (props: EmptyStateProps) => {
  const { section, className, cover = true, children } = props;
  const context = useAppContext();
  const emptyImage = getImage(section);

  const withPixiTrialist = isPixiTrialist(context);
  const renderEmptyImage = React.useCallback(() => {
    if (Array.isArray(emptyImage)) {
      return (
        <div className="stack-images-wrapper">
          {emptyImage.map((image, index) => (
            <img
              key={index}
              className={`empty-${index + 1}`}
              src={image}
              alt="Empty"
              data-testid="empty-state-image"
            />
          ))}
        </div>
      );
    }

    switch (section) {
      case "screen-new":
        return !context.shouldShowFeature(FEATURE_FLAGS_ENUM.RESELLER) ? (
          <EmptyScreenSection />
        ) : (
          <img src={emptyImage} alt="Empty" data-testid="empty-state-image" />
        );
      case "dashboard":
        return <EmptyDashboardSection />;
      default:
        return (
          cover &&
          emptyImage && (
            <img src={emptyImage} alt="Empty" data-testid="empty-state-image" />
          )
        );
    }
  }, [emptyImage, section, cover, context]);

  return (
    <Styled
      data-testid={`empty-${section}`}
      className={classNames("empty-state", `empty-${section}`, className, {
        "pixi-trialist": withPixiTrialist,
      })}
    >
      {withPixiTrialist && (
        <div className={"tagline pixi-trialist-tagline-before-image"}>
          {children}
        </div>
      )}
      {renderEmptyImage()}
      <div
        className={classNames("tagline", {
          "pixi-trialist-tagline-after-image": withPixiTrialist,
        })}
      >
        {children}
      </div>
      {section === "playlist-detail" && (
        <div className="empty-image">
          <img
            className="arrow"
            src={arrowImage}
            alt="arrow"
            data-testid="empty-state-arrow"
          />
        </div>
      )}
    </Styled>
  );
};

export default EmptyState;
